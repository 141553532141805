import { Company } from './company.types';

export enum StakeholderRole {
  STAKEHOLDER = 'stakeholder',
  CONTRIBUTOR = 'contributor',
  ADMIN = 'admin',
}
export enum StakeholderOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum StakeholderSortBy {
  NAME = 'fullName',
  COMPANY = 'company',
  STATUS = 'status',
}

export enum StakeholderType {
  ANGEL = 'angel',
  EMPLOYEE = 'employee',
  FOUNDER = 'founder',
  ADVISOR = 'advisor',
  BOARD_MEMBERS = 'board-members',
  VCS = 'vcs',
  INVESTMENT_BANKS = 'investment-banks',
  PES = 'pes',
  FAMILY_OFFICE = 'family-office',
  OTHER_INST = 'other-inst',
}

export const stakeholderTypeTitle: Record<StakeholderType, string> = {
  [StakeholderType.ADVISOR]: 'Advisors',
  [StakeholderType.ANGEL]: 'Angels',
  [StakeholderType.EMPLOYEE]: 'Employees',
  [StakeholderType.FOUNDER]: 'Founders',
  [StakeholderType.BOARD_MEMBERS]: 'Board Member',
  [StakeholderType.VCS]: 'Venture Capital',
  [StakeholderType.INVESTMENT_BANKS]: 'Investment Bank',
  [StakeholderType.PES]: 'Private Eq Firm',
  [StakeholderType.FAMILY_OFFICE]: 'Family Office',
  [StakeholderType.OTHER_INST]: 'Others',
};

export const stakeholderExTypeTitle: Record<StakeholderType, string> = {
  [StakeholderType.ADVISOR]: 'Ex-Advisor',
  [StakeholderType.ANGEL]: '',
  [StakeholderType.EMPLOYEE]: 'Ex-Employee',
  [StakeholderType.FOUNDER]: 'Ex-Founder',
  [StakeholderType.BOARD_MEMBERS]: '',
  [StakeholderType.VCS]: '',
  [StakeholderType.INVESTMENT_BANKS]: '',
  [StakeholderType.PES]: '',
  [StakeholderType.FAMILY_OFFICE]: '',
  [StakeholderType.OTHER_INST]: '',
};

export const stakeholderTypeTitleMap = Object.entries(stakeholderTypeTitle);

export const stakeholderRoleTitle: Record<StakeholderRole, string> = {
  [StakeholderRole.ADMIN]: 'Admin',
  [StakeholderRole.CONTRIBUTOR]: 'Contributor',
  [StakeholderRole.STAKEHOLDER]: 'Stakeholder',
};

export const stakeholderRoleTitleMap = Object.entries(stakeholderRoleTitle);

export enum TerminationCause {
  RESIGNATION = 'resignation',
  TERMINATION = 'termination',
  TERMINATION_WITH_CAUSE = 'termination-with-cause',
  DEATH = 'death',
  RETIREMENT = 'retirement',
}

export const TerminationCauseTitle: Record<TerminationCause, string> = {
  [TerminationCause.RESIGNATION]: 'Resignation',
  [TerminationCause.TERMINATION]: 'Termination',
  [TerminationCause.TERMINATION_WITH_CAUSE]: 'Termination With Cause',
  [TerminationCause.DEATH]: 'Death',
  [TerminationCause.RETIREMENT]: 'Retirement',
};

export enum StakeholderBulkAction {
  INVITE = 'invite',
  CANCEL_INVITATION = 'cancel-invitation',
  TOGGLE_CONTRIBUTOR = 'toggle-contributor',
  TOGGLE_ACCESS = 'toggle-access',
  DELETE = 'delete',
}

export type StakeholderBulkActionDto = {
  stakeholderIds: string[];
};

export interface Stakeholder {
  address?: string;
  birthday?: string;
  capTableAccess: boolean;
  capitalChangeAccess: boolean;
  company?: Company;
  companyTableAccess: boolean;
  countryOfInc?: unknown;
  createdAt: string;
  email: string;
  fullName: string;
  id: string;
  incDate?: string;
  isAccessRevoked: boolean;
  isActive: boolean;
  isInvited: boolean;
  lastActive: string;
  nationalityCountry?: unknown;
  passportExpDate?: string;
  passportNo?: string;
  phone?: string;
  phoneCountryIso2?: string;
  positionAccess: boolean;
  role: StakeholderRole;
  stakeholderCompanyId?: string;
  type: StakeholderType;
  votingRightsPercentage?: number;
  isTerminated: boolean;
  companyId?: string;
  terminationDate?: string;
  lastVestingDate?: string;
  terminationCause?: TerminationCause;
  deletedAt?: string;
}
